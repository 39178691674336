import React from 'react'
import Footers from './Footers'
import emailjs from 'emailjs-com';
import './ContactUs.css'
import  GoogleMap  from './GoogleMap'


export const ContactUs = () => {
    function sendEmail(e) {
        e.preventDefault();
    
        emailjs.sendForm('service_e19x9er', 'template_ckloou8', e.target, 'user_xBHvXYHkhfnDzFnpTpAqb')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset()
      }
    return (
        <div>
       
           <h1 className="goo_h1">Reach out to us</h1>
           <p className="goo_p">Office Hours: 10:00 a.m. to 8:00 p.m. Working day: Monday to Saturday. Extended office hours and weekends are available upon request.
            To schedule an appointment call +91 7022941183 / Mail to partner@lawsmithandco.in / use the form below.</p>
         <form className="contact-form" onSubmit={sendEmail}>
         
         <div  className="con_div1"  style={{display:'grid'}}>
         <label className="name">Name</label>
         <input type="text" className="name_input" name="name"/>
         </div>
         <div  className="con_div1"  style={{display:'grid'}}>
         <label className="last">Last Name</label>
<input className="last_input" type="text" name="lastname"/>
        
         </div>
         <div  className="con_div1"  style={{display:'grid'}}>
         <label className="email_name">Email</label>
         <input className="email_input" type="text" name="email"/>
         </div>
         <div  className="con_div1"  style={{display:'grid'}}>
         <label className="message_name">Message</label>
         <input className="email_input" type="text" name="message"/>
         </div>
<button className="button_input" value="Send" type="submit">Submit</button>
         </form>
          
           
         <h1 className="googl_h1">Find Us</h1>
   
   
     
  <br></br>
  <GoogleMap/>
  <br></br>
  <br></br>
  <Footers/>
        </div>
    )
}
