// import React from 'react'
// import './Why.css'
// import Image from '../Image/clock.webp'
// import Image1 from '../Image/coin.webp'
// import Image2 from '../Image/lock.webp'
// import Image3 from '../Image/people.webp'
// export const Why = () => {
//     return (
//         <div>
//            <h1 className="why-h1">Why Us</h1>
            
//             <img  src={Image} alt="time" className="why-img"/>
//             <h3 className="why-h3">Save Your Time</h3>
//             <img  src={Image1} alt="time" className="why-img1"/>
//             <h3 className="why-h3">No hidden charges *T&C</h3>
            
//             <img  src={Image2} alt="time" className="why-img2"/>
//             <h3 className="why-h3">Client confidentiality</h3>
//             <img  src={Image3} alt="time" className="why-img3"/>
//     <h3 className="why-h3">Highly professional team</h3>
    
//         </div>
//     )
// }


import React from 'react'

import './WhyUs.css'
import Image from './image/Unknown-12.jpg'
import Image1 from './image/Unknown-11.jpg'
import Image2 from './image/user.jpg'
import Image3 from './image/boy.png'
import Image4 from './image/law.png'

export const WhyUs=()=> {
   
    return (
        <div>
        <div className="whyus">
        <h1 className="why_h1">OUR PERFECT TEAM</h1>
        </div>
        <div>
        <img src={Image} alt="lawsmith" className="why_img" />
        <div class="square_why">
       <p className="why_square_p">Founding Partner
       Abhishek M.R
       Advocate & Solicitor
       High Court of Karnataka,
       Bangalore</p></div>


       <img src={Image1} alt="lawsmith" className="why_img1" />
        <div class="square_why1">
        <p className="why_p">Partner</p>
       <p className="why_square_p1">
       Sabarish Subramanian 
       Advocate on Record
       Supreme Court of India
       Delhi</p></div>

       <img src={Image2} alt="lawsmith" className="why_img2" />
        <div class="square_why2">
       <p className="why_square_p2">Consulting Partner 
       Alok
       Company Secretary</p></div>
       </div>
       <br></br>
       <br></br>

       <div>
       <img src={Image3} alt="lawsmith" className="why_img4" />
       <div class="square_why4">
      <p className="why_square_p4">Consulting Partner 
      Ankush 
      Company Secretary</p></div>


      
      <img src={Image4} alt="lawsmith" className="why_img3" />
       <div class="square_why3">
      <p className="why_square_p3">Consulting Partner 

      M.G Raghavendra 
      Advocate and Consultant 
      Bangalore</p></div>
      <br></br>
      </div>
     <br></br>
     <br></br>
     <br></br>
      
       <br></br>
        </div>
    )
}


