import React from 'react'
import './About.css'
import Image from '../image/about.jpg'
import { Button } from 'antd'
import {Link} from 'react-router-dom'

function About() {
        return (
        <div>
        <img src={Image} alt="about " className="about_img"/>
           <h1 className="about_h1">Services</h1> 
           <p className="about_p">Litigation</p>
           <p className="about_p">Registration/License</p>
           <p className="about_p">Corporate</p>
           <Link to="/service" ><Button className="about_button" ><p style={{fontFamily:'inherit'}}>SERVICES</p></Button></Link>
           </div>
    )
}

export default About
