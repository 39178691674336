import React from 'react'
import makeCarousel from 'react-reveal/makeCarousel';
import Slide from 'react-reveal/Slide';
import Image from './image/lawsmith.jpg'
import Image1 from './image/books (1).jpg'
import Image2 from './image/books1.jpg'
import Image3 from './image/books3.jpg'
import styled from 'styled-components';
import { Button } from 'antd'
import { Link } from 'react-router-dom'


const CarouselUI = ({ children }) => <Container>{children}</Container>;

const Carousel = makeCarousel(CarouselUI);
const Container = styled.div`

position: relative;
overflow: hidden;
width: 100%;
height: 640px;
padding: 0px 64px;
    align-items: flex-start;

.image{
  -webkit-filter: brightness(50%);
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;

 
}
.image1{
  -webkit-filter: brightness(50%);
    height: 650px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;

 
}
.hero {
  color: white;
  margin-top: -25%;
  margin-left: 32%;
  font-size: 4.4em;
  position: absolute;
  font-family: Open Sans, sans-serif;
}
 .hero_button {
  width: 39%;
  padding: 14px ,20px;
  padding: 7px;
  color: rgb(57,57,57);
  font-size: 38%;
  border: none;
  border-radius: 4px;
}
.heros {
  width: 100%;
  color: white;
  margin-top: -47%;
  margin-left: 36%;
  font-size: 2.4rem;
  position: absolute;
  font-family: Open Sans, sans-serif;
}
.hero_trade {
  width: 100%;
  color: white;
  margin-top: -43%;
  margin-left: 31%;
  font-size: 2.4rem;
  position: absolute;
  font-family: Open Sans, sans-serif;
}
.hero_crim {
  width: 100%;
  color: white;
  margin-top: -37%;
  margin-left: 31%;
  font-size: 2.4rem;
  position: absolute;
  font-family: Open Sans, sans-serif;
}


@media(max-width:420px){
  
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 465px;
.image{
  width: 100%;
  height: 450px;
}
.image1{
  width: 100%;
  height: 450px;
}
.hero {
  color: white;
  margin-top: -68.5%;
  margin-left: 4%;
  font-size: 2.4rem;
  width: 94%;
  text-align: center;
  font-family: Open Sans,sans-serif;
}
.heros {
  color: white;
  margin-top: -69%;
  margin-left: 0%;
  font-size: 2.4rem;
  width: 100%;
  text-align: center;
  font-family: Open Sans,sans-serif;
}
.hero_trade {
  width: 108%;
  color: white;
  margin-top: -70%;
  margin-left: -1%;
  font-size: 2.4rem;
  position: absolute;
  text-align: center;
  font-family: Open Sans,sans-serif;
}
.hero_crim {
  width: 100%;
  color: white;
  margin-top: -71%;
  margin-left: 0%;
  font-size: 2.4rem;
  position: absolute;
  text-align: center;
  font-family: Open Sans,sans-serif;
}
}

@media only screen and (min-width:360px) and (max-width:740px) and (orientation : landscape){
 
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 492px;
    padding: 0px 64px;
  
  .image {
    height: 100vh;
    width: 100%;
}
.image1{
  width: 100%;
  height: 100vh;
}
.hero {
  color: white;
  margin-top: -29%;
  margin-left: 4%;
  font-size: 2.4rem;
  width: 94%;
  font-family: Open Sans, sans-serif;
  text-align: center;
}
.hero_button {
  width: 20%;
  padding: 14px ,20px;
  padding: 12px;
  color: rgb(57,57,57);
  font-size: 38%;
  border: none;
  border-radius: 4px;
}
.hero_trade {
  width: 100%;
  color: white;
  margin-top: -29%;
margin-left: 7%;
  font-size: 2.4rem;
  position: absolute;
  font-family: Open Sans, sans-serif;
}
.heros {
  color: white;
  margin-top: -29%;
  margin-left: 4%;
  font-size: 2.4rem;
  width: 94%;
  text-align: center;
  font-family: Open Sans, sans-serif;
}
.hero_crim {
  width: 100%;
  color: white;
  margin-top: -29%;
  margin-left: 11%;
  font-size: 2.4rem;
  position: absolute;
  font-family: Open Sans, sans-serif;
}
}

@media only screen and (min-width:768px) and (max-width:1024px){
  
  .image {
    -webkit-filter: brightness(50%);
    height: 59vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}
.image1 {
  -webkit-filter: brightness(50%);
  height: 605px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.hero {
  color: white;
  margin-top: -45%;
  margin-left: 31%;
  font-size: 3.4rem;
  width: 90%;
  font-family: Open Sans, sans-serif;
}
.hero_button {
  width: 23%;
  padding: 14px ,20px;
  padding: 7px;
  color: rgb(57,57,57);
  font-size: 38%;
  border: none;
  border-radius: 4px;
}
.heros {
  color: white;
  margin-top: -44.3%;
  margin-left: 12%;
  font-size: 3.4rem;
  width: 90%;
  font-family: Open Sans,sans-serif;
}
.hero_trade {
  width: 100%;
  color: white;
  margin-top: -44.3%;
  margin-left: 2%;
  font-size: 3.4rem;
  position: absolute;
  font-family: Open Sans,sans-serif;
  text-align: center;
}
.hero_crim {
  width: 100%;
  color: white;
  margin-top: -44.3%;
  margin-left: 2%;
  font-size: 3.4rem;
  position: absolute;
  font-family: Open Sans,sans-serif;
  text-align: center;
}
}
@media only screen and (min-width:768px) and (max-width:1024px)and (orientation : landscape){
 
.hero {
  color: white;
  margin-top: -35%;
  margin-left: 37%;
  font-size: 3.4rem;
  font-family: Open Sans,sans-serif;
}
.heros {
  color: white;
  margin-top: -34.3%;
  margin-left: 21%;
  font-size: 3.4rem;
  width: 90%;
  font-family: Open Sans,sans-serif;
}
.image {
  -webkit-filter: brightness(50%);
  height: 70vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.hero_crim {
  width: 100%;
  color: white;
  margin-top: -34.3%;
  margin-left: 9%;
  font-size: 3.4rem;
  position: absolute;
  font-family: Open Sans,sans-serif;
}
.hero_trade {
  width: 100%;
  color: white;
  margin-top: -34.3%;
  margin-left: 5%;
  font-size: 3.4rem;
  position: absolute;
  font-family: Open Sans,sans-serif;
}
.image {
  -webkit-filter: brightness(50%);
  height: 81vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.image1 {
  -webkit-filter: brightness(50%);
  height: 623px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
}


`;

function HeroSection() {

  return (
    <div>

      <Carousel defaultWait={5000} /*wait for 1000 milliseconds*/ >

        <Slide right>
          <div  >
            <img src={Image} alt="lawsmith" className="image1"  ></img>

            <div className="hero" >
              <p> LAWSMITH & CO</p>
              <Link to='/about'> <Button className="hero_button"><span style={{ fontFamily: "Open Sans, sans-serif" }}>LEARN MORE</span></Button></Link>
            </div>

          </div>
        </Slide>
        <Slide right>
          <div  >
            <img src={Image1} alt="lawsmith" className="image"  ></img>
            <p className="heros">
              Mergers and Acquisitions</p>

          </div>
        </Slide>
        <Slide right>
          <div  >
            <img src={Image2} alt="lawsmith" className="image"  ></img>
            <p className="hero_trade">
              Trademark Registration & Prosecution</p>

          </div>
        </Slide>
        <Slide right>
          <div  >
            <img src={Image3} alt="lawsmith" className="image"  ></img>
            <p className="hero_crim" >
              Defending false criminal prosecution</p>

          </div>
        </Slide>
      </Carousel>

    </div>

  )
}

export default HeroSection





