import React from 'react';
import '../../App.css';

import Services from './Services';
import Footers from '../Footers';
import MeetTeam from './MeetTeam';
import About from './About';
import HeroSection from '../HeroSection';


function Home() {
  return (
    <>
   
  <HeroSection/>
     
    <Services/>
    <MeetTeam/>
    <About/>
   <Footers />
    
      
    </>
  );
}

export default Home;


