import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Services from './components/pages/Services';
import { Contact } from './components/Contact'
import { About } from './components/About';
import { WhyUs } from './components/WhyUs';
import { Service } from './components/Service';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function App() {
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));


  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div>

        <Dialog
          fullScreen={fullScreen}
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Disclaimer"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              As per the rules of the Bar Council of India, advocates are not permitted to solicit work and advertise.
              By clicking the “I Agree” and accessing to this website (www.lawsmithandco.in), you acknowledge and accept that you are seeking information for your own accord and there is no advertisement or solicitation  made by Lawsmith & Co or its members.
              The material/information provided here under is only for information purpose and should not be construed as legal advice.
              Lawsmith & Co shall not be liable for any consequence of any action taken by you relying on the material/information under this website.
              The content of this website is intellectual property of Lawsmith & Co.


            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <a href="/" style={{ textDecoration: 'none' }}><Button onClick={handleClose} >
              Disagree
            </Button></a>

            <Button onClick={handleClose} >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/services' component={Services} />
          <Route path='/service' component={Service} />
          <Route path='/about' component={About} />
          <Route path='/why' component={WhyUs} />
          <Route path='/contactus' component={Contact} />

        </Switch>
      </Router>
    </>
  );
}

export default App;



