import React from 'react'
import './Service.css'
import Image from './image/litigation.jpg'
import Image1 from './image/license.png'
import Image2 from './image/corporates.png'
import Image3 from './image/forums.png'
import Image4 from './image/png.png'
import Fade from 'react-reveal/Fade';
export const Service = () => {
    const numbers = ["Company law ","Information Technology" ,"Mergers"," Acquisition"," Amalgamation"," Trademark/Copyright" ," Realty/RERA" ,"Family matters Debt/recovery/insolvency"
        ," Consumer disputes"
        , "Service/employment"
        , "Contracts and Commercial disputes"
        , "Succession/Probate/Legal Heir certificate"
        , "Negotiable Instruments"
        ," Criminal trials"
        ," Arbitration and Mediation Legal Notices "];
const listItems = numbers.map((number) =>
  <li className="list">{number}</li>
);

const lists=["Trademark/copyright/patent/design"
    ,"Start-up India registration"
    ,"FSSAI"
    ,"Immovable Property"
    ,"RERA registration"
    ,"Company incorporation"
    ,"Corporation License"
    ,"Shops and Establishment License"
    ,"License under Factories Act"
    ,"Sale deed/GPA/Cancellation instruments"];
    const listItems1 = lists.map((list) =>
  <li className="list">{list}</li>
);


const register=[" Counselling concerning data privacy compliance ","IT Audit for companies","Handling cases before NCLT","Due diligence","Legal opinion", "Start-up legal compliances and registrations","Mergers and acquisitions", "Insolvency", "Reduction of share capital", "Company incorporation", 
"MOA, MOU, AOA etc Assistance on CSR","ESG","GRI Reporting and Sustainablility Reporting"];
const registers=register.map((corp)=>
<li className="list">{corp}</li>);

const form=["High Court of Karnataka" ," NCLT/NCLAT ","RERA Tribunal" ," DRT (Debt Recovery Tribunal)" ," KAT (Karnataka Administrative Tribunal)"
    ,"CAT (Central Administrative Tribunal)"
    , "TM registry, Chennai"
    ," Labour Court/Commissioner"
    ," Civil Court/Commercial Court"
    ,"Arbitration Tribunal"];
    const forums=form.map((forum)=>
    <li className="list">{forum}</li>);
const mode=["Retainership module (For companies)","Client based"]
const service=mode.map((modeservice)=>
<li className="list">{modeservice}</li>)
    return (
        <div>
            <div className="service">
                <h1 className="why_h1">Services</h1>
            </div>
            <Fade left>
                <div className="flex" >
                    <div class="square_service"> <h1 className="ser_h1">LITIGATION</h1>
                        <p className="ser_p"> {listItems}
                      </p>  </div>
                    <div class="square_service1"><img src={Image} alt="img" className="ser_img" /></div>
                </div></Fade>
            <br>
            </br> <Fade right>
                <div className="flex1" >
                    <div class="square_service"> <h1 className="ser_h1">REGISTRATION/LICENSE</h1>
                        <p className="ser_p">{listItems1} </p></div>
                    <div class="square_service1"><img src={Image1} alt="img" className="ser_img1" /></div>
                </div></Fade><br></br>
            <Fade left>
                <div className="flex2" >
                    <div class="square_service"> <h1 className="ser_h1">CORPORATE</h1>
                        <p className="ser_p">{registers}
                        </p>  </div>
                    <div class="square_service1"> <img src={Image2} alt="img" className="ser_img2" /></div>
                </div></Fade><br></br>
            <Fade right>
                <div className="flex3" >
                    <div class="square_service"> <h1 className="ser_h1">FORUMS</h1>
                        <p className="ser_p">{forums}
                        </p> </div>
                    <div class="square_service1"><img src={Image3} alt="img" className="ser_img3" /></div>
                </div></Fade><br></br>
            <Fade left>
                <div className="flex4" >
                    <div class="square_service">  <h1 className="ser_h1">MODE OF SERVICE</h1>
                        <p className="ser_p">{service}</p> </div>
                    <div class="square_service1"> <img src={Image4} alt="img" className="ser_img4" /></div>
                </div></Fade><br></br>

        </div>
    )
}
