import React from 'react'
import './Contact.css'
import { ContactUs } from './ContactUs'
export const Contact = () => {
    return (
        <div>
      <div className="contact">
     
      </div>
      <ContactUs/>
        </div>
    )
}
