import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Image from './image/index.png'
import './Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);
  

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  
  
  

  return (
    <>
      <nav className='navbar'>
     
        <div className='navbar-container'>
         
     
         
  <img src={Image} alt="hol" className="co"/>
  
  <div className='menu-icon' onClick={handleClick}>
  <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
</div>
<ul className={click ? 'nav-menu active' : 'nav-menu'}>
<li className='nav-item'>
  <Link to='/' className='nav-links' onClick={closeMobileMenu}> HOME</Link>
</li>
<li className='nav-item'>
  <Link to='/service' className='nav-links' onClick={closeMobileMenu}> SERVICES</Link>
</li>

<li className='nav-item'>
<Link to='/why' className="nav-links" onClick={closeMobileMenu}>TEAM</Link>
</li>
<li className='nav-item'>
<Link to='/about' className="nav-links" onClick={closeMobileMenu}>ABOUT US</Link>
</li>

<li>
<Link to='/contactus' className="nav-links" onClick={closeMobileMenu}>CONTACT US</Link>
</li>

</ul>

</div>
</nav>
</>
);
}

export default Navbar;