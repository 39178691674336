import React from 'react'
import './MeetTeam.css'
import Image from '../image/team.jpg'
import { Button } from 'antd'
import {Link} from 'react-router-dom'

function MeetTeam() {
    return (
        <div>
            <img src={Image} alt="lawsmith" className="team"/>
            <h1 className="team_h1">Team</h1>
            <p className="team_p">Abhishek has been licensed to practice law in India since 2016. He's tried numerous civil and criminal cases to verdict in state and district courts. 
            In addition, Abhishek has substantial experience representing clients in civil and criminal matters</p>
        <Link to="/why" ><Button className="team_button" ><p style={{fontFamily:'inherit'}}>MEET THE TEAM</p></Button></Link>
            </div>
    )
}

export default MeetTeam
