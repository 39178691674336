import React from "react";
import "./About.css";
import makeCarousel from "react-reveal/makeCarousel";
import Image from "./image/circlecrop.jpg";
import styled from "styled-components";

const CarouselUI = ({ children }) => <Container>{children}</Container>;

const Carousel = makeCarousel(CarouselUI);
const Container = styled.div`


position: relative;
overflow: hidden;
width: 100%;
height:300px;
padding: 0px 64px;
    align-items: flex-start;

    .para {
      width: 97%;
      font-size: 150%;
      margin-left: 1%;
      text-align: center;
      margin-top: 3%;
      font-size: x-large;
      font-style: italic;
      color: white;
      font-family: 'Font Awesome 5 Free'
  }
  .head_p {
    width: 73%;
    font-size: 150%;
    margin-left: 13%;
    text-align: center;
    margin-top: 10%;
    font-size: x-large;
    font-family: "Open Sans",sans-serif;
    color:white;
}
.img_car {
  width: 8%;
  margin-left: 45%;
  margin-top: 2%;
}
.circle {
  width: 10%;
  margin-left: 45%;
  margin-top: 0%;
}

  

    @media only screen and (min-width:360px) and (max-width:740px){
      .img_car {
        width: 46%;
        margin-left: 27%;
        margin-top: 13%;
    }
    
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 461px;
      padding: 0px 64px;
      -webkit-align-items: flex-start;
      -webkit-box-align: flex-start;
      -ms-flex-align: flex-start;
      align-items: flex-start;
      .para {
        width: 100%;
        font-size: 150%;
        margin-left: 0%;
        text-align: center;
        margin-top: 52%;
        font-size: x-large;
        font-style: italic;
        font-family: 'Font Awesome 5 Free'
    }
    .img_car {
      width: 42%;
      margin-left: 27%;
      margin-top: 14%;
  }
  .head_p {
    width: 96%;
    font-size: 150%;
    margin-left: 1%;
    text-align: center;
    margin-top: 3%;
    font-size: x-large;
    font-family: "Open Sans",sans-serif;
}

.circle {
  width: 42%;
  margin-left: 27%;
  margin-top: 16%;
}
  
    }
@media only screen and (min-width:360px) and (max-width:740px) and (orientation : landscape){
  .img_car {
    width: 14%;
    margin-left: 43%;
    margin-top: 8%;
}
.para {
  width: 100%;
  font-size: 150%;
  margin-left: 0%;
  text-align: center;
  margin-top: 16%;
  font-size: x-large;
  font-style: italic;
  font-family: 'Font Awesome 5 Free'
}
.circle {
  width: 16%;
  margin-left: 41%;
  margin-top: 7%;
}
}
@media only screen and (min-width:768px) and (max-width:1024px){
  .img_car {
    width: 17%;
    margin-left: 42%;
    margin-top: 5%;
}.head_p {
  width: 73%;
  font-size: 150%;
  margin-left: 13%;
  text-align: center;
  margin-top: 1%;
  font-size: x-large;
  font-family: "Open Sans",sans-serif;
  color: white;
}
.para {
  width: 100%;
  font-size: 150%;
  margin-left: 0%;
  text-align: center;
  margin-top: 20%;
  font-size: x-large;
  font-style: italic;
  color: white;
  font-family: 'Font Awesome 5 Free'
}
.circle {
  width: 16%;
  margin-left: 41%;
  margin-top: 6%;
}
  
}
@media only screen and (min-width:768px) and (max-width:1024px)and (orientation : landscape){
  .img_car {
    width: 12%;
    margin-left: 43%;
    margin-top: 5%;
}
.para {
  width: 96%;
  font-size: 150%;
  margin-left: 0%;
  text-align: center;
  margin-top: 16%;
  font-size: x-large;
  font-style: italic;
  font-family: 'Font Awesome 5 Free'
  color: white;
}
.circle {
  width: 13%;
  margin-left: 43%;
  margin-top: 5%;
}
}
`;

export const About = () => {
  return (
    <div>
      <div className="aboutus">
        <h1 className="why_h1">About Us</h1>
      </div>
      <p className="aboutus_p">
        Lawsmith & Co (L&C) is an innovative boutique lawfirm with a unique
        combination of Advocates and Company Secretaries offering variety of
        legal strategies to all the sectors of the society ranging from private
        persons, companies, startups, LLPs , NGOs, hospitals, industries,
        student communities, educational institutions, employees to public
        spirited citizens etc
      </p>
      <p className="aboutus_p">
        Besides, the firm also undertakes pro bono cases for indigent litigants
        in exceptional circumstances.
      </p>

      <p className="aboutus_p">
        L&C’s corporate services focus on corporate formation, counselling
        concerning data privacy compliance and IT Audit for companies, handling
        cases before NCLT, due diligence, legal opinion, start-up legal
        compliances and registrations, mergers and acquisitions, insolvency,
        reduction of share capital, company incorporation, MOA, MOU, AOA etc
      </p>
      <p className="aboutus_p">
        Abhishek M.R has been licensed to practice law in India since 2016. He's
        tried numerous civil and criminal cases to verdict in state and district
        courts. In addition, He has substantial experience representing clients
        in civil and criminal matters.He is constantly developing
        result-oriented strategies for an effective and a time bound redressal
        of the dispute of his clients. He will listen intently to your concerns
        and work with you to develop a strategy to reach a satisfactory
        resolution to your dispute or issue.
      </p>
      <p className="aboutus_p">
        He obtained a B.B.A.LL.B degree from JSS Law College, Mysuru(Affiliated
        to Karnataka State Law University). He has also obtained a PG Dip, in
        Cyber Law from the National Law University, Hyderabad.{" "}
      </p>
      <p className="aboutus_p">
        He has completed his Master of Business Law from National Law School of
        India Bangalore.
      </p>
      <p className="aboutus_p">
        Abhishek is an empanelled Advocate for State Bank of India and Mysore
        Urban Development Authority before all the courts at Bengaluru.
      </p>

      <div className="caro">
        <Carousel /*wait for 1000 milliseconds*/>
          <img src={Image} alt="lawsmith" className="circle" />

          <div>
            <div className="hero">
              <p className="head_p">Founding Partner’s Message:</p>

              <p className="para" style={{ height: "150px" }}>
                "L&C will endeavour to provide you with an unparalleled result
                oriented legal strategy devised by our tailored team and timely
                updates on your case status to ensure a smooth and a hassle free
                litigation experience."{" "}
              </p>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
};
