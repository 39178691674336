import React from 'react'
import './Footers.css'
import Image from './image/Footer-Background.jpg'
const Footers = () => {
  return (
    <div>
      <img src={Image} alt="footer" className="footer"></img>
      <div className="direction" >
        <div class="square_footer">
          <div className="address">

            <h4 style={{ fontFamily: "Open Sans, sans-serif", color: 'white' }} >Banglore</h4>
            <br></br>
            <p style={{ fontFamily: "Open Sans, sans-serif", lineHeight: '1.55rem', color: 'white' }}>No.22,2nd Floor,Nehru Nagar Main Road,Sheshadripuram,Near Reddy petrol bunk, Bengaluru, Karnataka 560020<br></br>
              <i class="fa fa-phone" aria-hidden="true"></i>+91 7022941183
            </p>
          </div>
        </div>
        <div class="square_footer1"> <div className="address1" >
         
          <h4 style={{ fontFamily: "Open Sans, sans-serif", color: 'white' }}>Mysore</h4>

          <br></br>
          <p style={{ fontFamily: "Open Sans, sans-serif", lineHeight: '1.55rem', color: 'white' }}>61 Laksh Kuvempu Layout, Dattagalli 3rd Stage, Mysore 570033
          <br></br><i class="fa fa-phone" aria-hidden="true"></i>+91 7022941183
          </p>

        </div></div>
        <div class="square_footer2"><div className="address2">

          <h4 style={{ fontFamily: "Open Sans, sans-serif", color: 'white' }}>Delhi</h4>
          <br></br>
          <p  style={{ fontFamily: "Open Sans, sans-serif", lineHeight: '1.55rem',color: 'white' }}>Advocate On Record
            Supreme Court of India
            163, Tower No.9, Supreme Enclave,
            Mayur Vihar Phase-1, Delhi-110091<br></br>
            <i class="fa fa-phone" aria-hidden="true"></i>+91 9871006445 ,
            +91 8510008811


          </p></div></div>
      </div>
    </div>
  )
}

export default Footers
